import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import replaceUrl from "replace-url-by-params"
import queryString from "query-string"
import { format } from 'date-fns';

// Components
import {
  RichText,
  ShareButtons,
  Button,
  StoreStats,
  Footer,
  Asset,
} from "../components"

const Page = ({ data, location }: any) => {
  const {
    title,
    subtitle,
    author,
    ctaLink,
    buttonsColor,
    footerColor,
    showShareButtons,
    showStoreStats,
    showFooter,
    appLogo,
    quotes,
    paragraphUnderQuotes,
    paragraphUnderFooter,
    paragraph1,
    paragraph2,
    paragraph3,
    asset1,
    asset2,
  } = data.contentfulTaboolaPage

  const {
    taboola_click_id,
    click_id,
    campaign,
    utm_source,
    adgroup,
    campaign_item_id,
    creative,
  }: any = queryString.parse(location.search)

  const [finalCtaLink, setFinalCtaLink] = useState("")
  useEffect(() => {
    setFinalCtaLink(
      replaceUrl(ctaLink, {
        network_name: utm_source,
        campaign_name: campaign,
        adgroup_name: adgroup,
        taboola_click_id: taboola_click_id || click_id,
        campaign_item_id,
        creative,
      })
    )
  }, [])

  if (finalCtaLink == "") {
    return (
      <div className="w-screen h-screen flex justify-center items-center font-extralight text-2xl">
        Loading...
      </div>
    )
  }

  return (
    <main>
      <article className="container m-auto px-8 py-3 flex flex-col gap-3">
        <div className="text-[8px] text-center font-[600] text-gray-400">
          ADVERTORIAL
        </div>
        <section className="flex justify-between items-center">
          <div className="font-bold">Slot news</div>
          <div>
            <Button
              title="DOWNLOAD APP"
              url={finalCtaLink}
              color={buttonsColor}
            />
          </div>
        </section>
        <hr />

        <div className="flex flex-col gap-2">
          <h1 className="text-[20px] text-black font-semibold">{title}</h1>
          <div className="text-[10px] text-[#262323]">
            <h2>{subtitle.replace('{date}', format(new Date(), 'MMMM d, yyyy'))}</h2>
            <h3 className="text-green-800 font-bold">{author}</h3>
          </div>
        </div>

        <section>
          {
            <RichText
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={paragraph1}
            />
          }
        </section>
        {showShareButtons && <ShareButtons color={buttonsColor} />}

        <div className="flex flex-col gap-2 justify-center items-center">
          <Asset data={asset1} />
          <div className="font-bold">
            Social Casino Slots Go Viral – Legally and For Free!
          </div>
        </div>

        <section>
          {
            <RichText
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={paragraph2}
            />
          }
        </section>
        <section className="quotes flex flex-col gap-6 px-8 pt-8">
          {
            <RichText
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={quotes}
            />
          }
        </section>

        {!!paragraphUnderQuotes && (
          <section>
            <RichText
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={paragraphUnderQuotes}
            />
          </section>
        )}
        <hr />

        <div className="flex justify-center items-center">
          <Asset data={asset2} />
        </div>

        {showStoreStats && (
          <StoreStats
            appLogo={appLogo}
            ctaLink={finalCtaLink}
            buttonsColor={buttonsColor}
          />
        )}

        <hr />

        <section>
          {
            <RichText
              contentfullText={paragraph3}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
            />
          }
        </section>

        <div className="text-center my-4">
          <Button
            title="Download and play for free!"
            url={finalCtaLink}
            color={buttonsColor}
            fontSize={14}
            maxWidth={"138px"}
          />
        </div>
      </article>

      {showFooter && <Footer ctaLink={finalCtaLink} color={footerColor} />}
      {!!paragraphUnderFooter && (
        <section>
          <RichText
            ctaLink={finalCtaLink}
            buttonsColor={buttonsColor}
            contentfullText={paragraphUnderFooter}
          />
        </section>
      )}
    </main>
  )
}

export const Head = ({ data }: any) => {
  return (
    <>
      <title>{data.contentfulTaboolaPage.title}</title>
      {data.contentfulTaboolaPage.pixels && (
        <script>{data.contentfulTaboolaPage.pixels.pixels}</script>
      )}
    </>
  )
}

export const data = graphql`
  query defaultPage($slug: String) {
    contentfulTaboolaPage(slug: { eq: $slug }) {
      title
      subtitle
      author
      ctaLink
      buttonsColor
      footerColor
      showShareButtons
      showStoreStats
      showFooter
      logo {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 68)
          }
        }
      }
      appLogo {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 79)
          }
        }
      }
      asset1 {
        title
        publicUrl
        file {
          contentType
        }
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
          }
        }
      }
      asset2 {
        title
        publicUrl
        file {
          contentType
        }
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
          }
        }
      }
      quotes {
        raw
      }
      paragraph1 {
        raw
      }
      paragraph2 {
        raw
      }
      paragraph3 {
        raw
      }
      pixels {
        pixels
      }
    }
  }
`

export default Page
